import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "hours", "week", "month", "all" ]

  connect() {
    const params = new URLSearchParams(window.location.search)
    const filter = params.get('filter')
    const button = eval(`this.${filter}Target`)

    if (!filter) {
      this.monthTarget.classList.remove('btn-light')
      this.monthTarget.classList.add('btn-primary')
    }

    if (button) {
      button.classList.remove('btn-light')
      button.classList.add('btn-primary')
    }
  }
}
