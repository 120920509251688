import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "input" ]

    connect() {
        const url = this.data.get("url")
        fetch(`${url}&type=${this.data.get("type")}`)
            .then(response => response.json())
            .then(data => {
                this.inputTarget.textContent = `${data.value} ${this.data.get("unit")}`
            })
    }
}